// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

// Vendor node_modules 
var $ = require('jquery');
var jQueryBridget = require('jquery-bridget');


require('@fancyapps/fancybox');
require('flickity');
require('flickity-imagesloaded');
require('flickity-fade');
require('material-scrolltop');


// Scripts
require('./modules/scripts');



	









